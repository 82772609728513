import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Toast from "react-bootstrap/Toast";
import ClipboardButton from "./ClipboardButton";

import {
  requestSlinkCreation,
  registerMessageHandler,
  unregisterMessageHandler
} from "../socket/api.js";

const SHORTLINK_NOUN = "Shortl";
const URL_BASE = "shortl.io/";

class SlinkCreationCard extends React.Component {
  state = {
    target_url: "",
    specify_custom_slink: false,
    custom_slink: "",
    show_toast: false,
    error_message: "",
    response: ""
  };

  componentDidMount = () => {
    registerMessageHandler(
      "slink_creation_response",
      this.handleResponseFromServer
    );
  };

  componentWillUnmount = () => {
    unregisterMessageHandler("slink_creation_response");
  };

  handleSubmit = () => {
    if (this.state.target_url) {
      requestSlinkCreation(
        this.state.target_url,
        this.state.specify_custom_slink ? this.state.custom_slink : ""
      );
    }
  };

  handleResponseFromServer = response => {
    if (response.creation_status === "OK") {
      // Clear the creation card input data and show success toast.
      this.setState({
        show_toast: true,
        target_url: "",
        specify_custom_slink: false,
        custom_slink: "",
        response: response
      });
    } else {
      var error_message;
      switch (response.creation_status) {
        case "SLINK_ALREADY_EXISTS":
          error_message =
            "This slink already exists, please try specifying a different custom slink.";
          break;
        case "ERROR":
          error_message =
            "Oops, an error occured on our server. Please try again in a few minutes.";
          break;
        default:
          error_message =
            "Uh oh, an unknown error occured. Please email shortl@krentsel.com.";
          break;
      }
      this.setState({
        show_toast: true,
        error_message: error_message,
        specify_custom_slink: false
      });
    }
  };

  successToast() {
    return (
      <Toast
        show={this.state.show_toast}
        onClose={() => this.setState({ show_toast: false })}
      >
        <Toast.Header>
          <strong className="mr-auto">
            {SHORTLINK_NOUN} Registry Response
          </strong>
        </Toast.Header>
        <Toast.Body>
          Your {SHORTLINK_NOUN.toLowerCase()} was created successfully!{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"//" + URL_BASE + this.state.response.slink}
          >
            {URL_BASE}
            {this.state.response.slink}
          </a>{" "}
          will redirect to {this.state.response.target_url}.
        </Toast.Body>
        <Toast.Body>
          <ClipboardButton
            text={"www." + URL_BASE + this.state.response.slink}
          />
        </Toast.Body>
      </Toast>
    );
  }

  errorToast() {
    return (
      <Toast
        show={this.state.show_toast}
        onClose={() => this.setState({ show_toast: false, error_message: "" })}
      >
        <Toast.Header>
          <strong className="mr-auto">
            {SHORTLINK_NOUN} Registry Response
          </strong>
        </Toast.Header>
        <Toast.Body>{this.state.error_message}</Toast.Body>
      </Toast>
    );
  }

  toast() {
    return this.state.error_message ? this.errorToast() : this.successToast();
  }

  render() {
    return (
      <Card>
        {this.state.show_toast ? (
          this.toast()
        ) : (
          <Card.Body>
            <Card.Title>Create a {SHORTLINK_NOUN}</Card.Title>
            <Card.Text>
              <Form>
                <Form.Group controlId="formTargetUrl">
                  <Form.Label>URL to shorten</Form.Label>
                  <Form.Control
                    type="url"
                    placeholder="Enter URL"
                    value={this.state.target_url}
                    onChange={event => {
                      this.setState({ target_url: event.target.value });
                    }}
                  />
                  <Form.Text className="text-muted">
                    Put it any URL and we'll redirect you there!
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formShowCustomLink">
                  <Form.Check
                    id="specify-custom-link-checkbox"
                    type="checkbox"
                    label="Specify Custom Shortl"
                    checked={this.state.specify_custom_link}
                    onChange={event => {
                      this.setState({
                        specify_custom_slink: event.target.checked
                      });
                    }}
                  />
                </Form.Group>
                {this.state.specify_custom_slink && (
                  <Form.Group controlId="formCustomSlink">
                    <Form.Control
                      type="text"
                      placeholder={"Enter custom " + SHORTLINK_NOUN}
                      value={this.state.custom_slink}
                      onChange={event => {
                        this.setState({ custom_slink: event.target.value });
                      }}
                    />
                  </Form.Group>
                )}
                <Button variant="primary" onClick={this.handleSubmit}>
                  {SHORTLINK_NOUN} It
                </Button>
              </Form>
            </Card.Text>
          </Card.Body>
        )}
      </Card>
    );
  }
}

export default SlinkCreationCard;
