import socketIOClient from "socket.io-client";

// was "http://localhost:3000"
const socket = socketIOClient("https://frozen-ravine-19952.herokuapp.com/", {
  reconnectionAttempts: 5
});

function registerMessageHandler(name, onMessageReceived) {
  socket.on(name, onMessageReceived);
}

function unregisterMessageHandler(name) {
  socket.off(name);
}

// Send a request to the backend to create a slink entry in the database.
function requestSlinkCreation(target_url, slink) {
  console.log("Client api called requestSlinkCreation with " + target_url);
  console.log("Slink request:" + slink);
  socket.emit("slink_creation_request", {
    target_url: target_url,
    slink: slink
  });
}

export {
  socket,
  registerMessageHandler,
  unregisterMessageHandler,
  requestSlinkCreation
};
