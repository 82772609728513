import React from "react";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// Thanks StackOverflow: https://stackoverflow.com/a/60848078/4015623
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class ClipboardButton extends React.Component {
  state = {
    copied: false,
    copied_tooltip_text: this.props.copied_tooltip_text || "Copied",
    button_text: this.props.button_text || "Copy"
  };

  render() {
    return (
      <div>
        <ConditionalWrapper
          condition={this.state.copied}
          wrapper={children => (
            <OverlayTrigger
              overlay={<Tooltip>{this.state.copied_tooltip_text}</Tooltip>}
              placement="top"
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <CopyToClipboard
            text={this.props.text}
            onCopy={() => this.setState({ copied: true })}
          >
            <Button variant="outline-primary">{this.state.button_text}</Button>
          </CopyToClipboard>
        </ConditionalWrapper>
      </div>
    );
  }
}

export default ClipboardButton;
