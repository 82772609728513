import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import SlinkCreationCard from "./components/SlinkCreationCard.js";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <SlinkCreationCard />
      </header>
    </div>
  );
}

export default App;
